import React, { Fragment } from 'react';
import { Grid, Row, Col } from '@freecodecamp/react-bootstrap';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { uniq } from 'lodash';
import { Spacer } from '../helpers';
import store from 'store';

import './landing.css';
import '../Map/map.css';

const propTypes = {
  edges: PropTypes.array
};

function getParams() {
  var query = typeof window !== 'undefined' ? window.location.search.substring(1) : '';

  var vars = query.split('&');
  var data = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    data[pair[0]] = pair[1];
  }
  store.set('user_id', null);
  store.set('api_key', null);
  if (data.hasOwnProperty('lt') && data.hasOwnProperty('api_key')) {
    store.set('user_id', data['lt']);
    store.set('api_key', data['api_key']);
    return '?lt=' + data['lt'] + '&api_key=' + data['api_key'];
  }
  return '';
}

export const Landing = ({ edges }) => {
  const superBlocks = uniq(edges.map(element => element.node.superBlock));
  // console.log(superBlocks);
  return (
    <Fragment>
      <Helmet>
        <title>Learn to code</title>
      </Helmet>
      <main className='landing-page'>
        <Spacer />
        <Grid>
          <Row>
            <Col sm={10} smOffset={1} xs={12}>
              <Row>
                <Col sm={10} smOffset={1} xs={12}>
                  <Spacer />
                  <h1 className='text-center big-heading'>WELCOME</h1>
                </Col>
              </Row>
              <Spacer />
              <Spacer />
              <p className='text-center'>
                We have thousands of coding lessons to help you improve your
                skills.
              </p>
              <p className='text-center'>
                You can earn each certification by completing its 5 final
                projects.
              </p>
              <p className='text-center'>
                If you are new to coding, we recommend you start at the
                beginning.
              </p>
              <Spacer />
              <ul>
                {superBlocks.map((superBlock, i) => (
                  <li className={'superblock'} key={i}>
                    <Link
                      state={{ superBlock: superBlock }}
                      to={`/learn` + getParams()}
                    >
                      <h2 className='medium-heading'>
                        {superBlock} <span></span>
                      </h2>
                    </Link>
                  </li>
                ))}
              </ul>
              <Spacer />
              <Spacer />
            </Col>
          </Row>
        </Grid>
      </main>
    </Fragment>
  );
};

Landing.displayName = 'Landing';
Landing.propTypes = propTypes;
export default Landing;
