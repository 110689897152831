const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => m && m.default || m


exports.components = {
  "component---src-templates-challenges-classic-show-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/templates/Challenges/classic/Show.js"))),
  "component---src-templates-challenges-projects-frontend-show-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/templates/Challenges/projects/frontend/Show.js"))),
  "component---src-templates-challenges-projects-backend-show-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/templates/Challenges/projects/backend/Show.js"))),
  "component---src-templates-introduction-super-block-intro-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/templates/Introduction/SuperBlockIntro.js"))),
  "component---src-templates-introduction-intro-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/templates/Introduction/Intro.js"))),
  "component---cache-dev-404-page-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/.cache/dev-404-page.js"))),
  "component---src-pages-404-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/404.js"))),
  "component---src-pages-accept-privacy-terms-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/accept-privacy-terms.js"))),
  "component---src-pages-certification-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/certification.js"))),
  "component---src-pages-index-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/index.js"))),
  "component---src-pages-learn-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/learn.js"))),
  "component---src-pages-search-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/search.js"))),
  "component---src-pages-unsubscribed-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/unsubscribed.js"))),
  "component---src-pages-update-email-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/update-email.js"))),
  "component---src-pages-user-js": hot(preferDefault(require("/var/www/html/code.gigcapitol.com/client/src/pages/user.js")))
}

