import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'gatsby';

import ga from '../../../analytics';
import { makeExpandedBlockSelector, toggleBlock } from '../redux';
import { completedChallengesSelector } from '../../../redux';
import Caret from '../../../assets/icons/Caret';
import { blockNameify } from '../../../../utils/blockNameify';
import GreenPass from '../../../assets/icons/GreenPass';
import GreenNotCompleted from '../../../assets/icons/GreenNotCompleted';
import { dasherize } from '../../../../../utils/slugs';
import axios from 'axios';

const mapStateToProps = (state, ownProps) => {
  const expandedSelector = makeExpandedBlockSelector(ownProps.blockDashedName);

  return createSelector(
    expandedSelector,
    completedChallengesSelector,
    (isExpanded, completedChallenges) => ({
      isExpanded,
      completedChallenges: completedChallenges.map(({ id }) => id)
    })
  )(state);
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleBlock }, dispatch);

const propTypes = {
  blockDashedName: PropTypes.string,
  challenges: PropTypes.array,
  completedChallenges: PropTypes.arrayOf(PropTypes.string),
  intro: PropTypes.shape({
    fields: PropTypes.shape({ slug: PropTypes.string.isRequired }),
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
      block: PropTypes.string.isRequired
    })
  }),
  isExpanded: PropTypes.bool,
  toggleBlock: PropTypes.func.isRequired
};

const mapIconStyle = { height: '15px', marginRight: '10px', width: '15px' };

export class Block extends Component {
  constructor(...props) {
    super(...props);
    this.state = { data: 0 };
    this.handleBlockClick = this.handleBlockClick.bind(this);
    this.handleChallengeClick = this.handleChallengeClick.bind(this);
    this.renderChallenges = this.renderChallenges.bind(this);
  }

  handleBlockClick() {
    const { blockDashedName, toggleBlock } = this.props;
    ga.event({
      category: 'Map Block Click',
      action: blockDashedName
    });
    return toggleBlock(blockDashedName);
  }

  handleChallengeClick(slug) {
    return () => {
      return ga.event({
        category: 'Map Challenge Click',
        action: slug
      });
    };
  }

  renderCheckMark(isCompleted) {
    return isCompleted ? (
      <GreenPass style={mapIconStyle} />
    ) : (
      <GreenNotCompleted style={mapIconStyle} />
    );
  }
  getParams() {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    var data = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      data[pair[0]] = pair[1];
    }
    if (data.hasOwnProperty('lt') && data.hasOwnProperty('api_key')) {
      return '?lt=' + data['lt'] + '&api_key=' + data['api_key'];
    }
    return '';
  }
  renderChallenges(intro = {}, challenges = []) {
    // TODO: Split this into a Challenge Component and add tests
    // TODO: The styles badge and map-badge on the completion span do not exist
    return [intro].concat(challenges).map(challenge => {
      const completedClass = challenge.isCompleted
        ? ' map-challenge-title-completed'
        : '';
      return (
        <li
          className={'col-md-3 map-challenge-title' + completedClass}
          id={
            challenge.title
              ? dasherize(challenge.title)
              : dasherize(challenge.frontmatter.title)
          }
          key={'map-challenge' + challenge.fields.slug}
        >
          <div className='col-xs-12'>
            {challenge.title || challenge.frontmatter.title}
            <span>
              <Link
                onClick={this.handleChallengeClick(challenge.fields.slug)}
                to={challenge.fields.slug + this.getParams()}
              >
                <p>Start Coding</p>
              </Link>
            </span>
          </div>
        </li>
      );
    });
  }
  async componentDidMount() {
    let count = 0;
    let length = this.props.challenges.length;
    let api_key = localStorage.getItem('api_key')
    let user_id = localStorage.getItem('user_id')

    let url ='https://codetool2.com/api/completed?filter[where][block]='+ this.props.blockDashedName+'&filter[where][user_id]='+user_id+'&filter[where][api_key]='+api_key
    url =url.replace(/['"]+/g, '')
    await axios.get(url).then(res =>{
      count  =res.data.length
    })
    this.setState({ data: count });
  }
  render() {
    const {
      blockDashedName,
      completedChallenges,
      challenges,
      isExpanded,
      intro
    } = this.props;
    let completedCount = 0;
    const challengesWithCompleted = challenges.map(challenge => {
      const { id } = challenge;
      const isCompleted = completedChallenges.some(
        completedId => id === completedId
      );
      if (isCompleted) {
        completedCount++;
      }
      return { ...challenge, isCompleted };
    });

    return (
      <li className={`block ${isExpanded ? 'open' : ''}`}>
        <button
          aria-expanded={isExpanded}
          className='map-title'
          onClick={this.handleBlockClick}
        >
          <Caret />
          <h4>{blockNameify(blockDashedName)}</h4>
          <div className='map-title-completed'>
            <span>{`${this.state.data}/${challengesWithCompleted.length}`}</span>
          </div>
        </button>
        <div className='row'>
          <ul style={{ padding: '0' }}>
            {isExpanded
              ? this.renderChallenges(intro, challengesWithCompleted)
              : null}
          </ul>
        </div>
      </li>
    );
  }
}

Block.displayName = 'Block';
Block.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Block);
