import React, { Fragment } from 'react';
import { Row, Col } from '@freecodecamp/react-bootstrap';

import { Spacer } from '../helpers';

import './welcome.css';

function Welcome() {
  return (
    <Fragment>
      <Row>
        <Col sm={10} smOffset={1} xs={12}>
          <Spacer />
          <h1 className='text-center big-heading'>WELCOME</h1>
        </Col>
      </Row>
      <Spacer />
      <Row className='text-center quote-partial'>
        <Col sm={10} smOffset={1} xs={12}>
          <p className='text-center'>
            Learn to code the thousands of lessons that we offer.
          </p>
          <p className='text-center'>
            You can earn each certification by completing its 5 final projects.
          </p>
          <p className='text-center'>
            If you are new to coding, we recommend you start at the beginning.
          </p>
        </Col>
      </Row>
    </Fragment>
  );
}

Welcome.displayName = 'Welcome';

export default Welcome;
